import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import StorageIcon from "@mui/icons-material/Storage";
import SettingsIcon from "@mui/icons-material/Settings";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import DescriptionIcon from "@mui/icons-material/Description";
import { IconButton } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import appStateSlice from "../redux/appStateSlice";
import { memo, useEffect } from "react";
import ViewListIcon from "@mui/icons-material/ViewList";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export function TemporaryDrawer() {
  const theme = useTheme();
  const stores = useSelector((state: RootState) => state.user.stores);
  const open = useSelector((state: RootState) => state.app.drawerOpen);
  const userRole = useSelector((state: RootState) => state.user.role);
  const userId = useSelector((state: RootState) => state.user.userId);

  const dispatch = useDispatch();

  return (
    <Drawer variant="permanent" anchor="left" open={open}>
      <DrawerHeader>
        <IconButton
          onClick={() => dispatch(appStateSlice.actions.setDrawerOpen(false))}
        >
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        <ListItem
          key="Create-new-store"
          disablePadding
          sx={{ display: "block" }}
        >
          <ListItemButton
            {...{ component: Link, to: "/create" }}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <AddIcon />
            </ListItemIcon>
            <ListItemText
              primary="新規ストアの作成"
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
        {stores ? (
          stores.map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                {...{ component: Link, to: "/store/" + text }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <StorageIcon />
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))
        ) : (
          <div></div>
        )}
      </List>
      <Divider />
      <List>
        <ListItem key="Settings" disablePadding sx={{ display: "block" }}>
          <ListItemButton
            {...{ component: Link, to: "/settings" }}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="設定" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        {userRole === "admin" ? (
          <div>
            <ListItem key="News" disablePadding sx={{ display: "block" }}>
              <ListItemButton
                {...{ component: Link, to: "/admin/news" }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <AnnouncementIcon />
                </ListItemIcon>
                <ListItemText
                  primary="ニュース"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem key="Users" disablePadding sx={{ display: "block" }}>
              <ListItemButton
                {...{ component: Link, to: "/admin/users" }}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <ManageAccountsIcon />
                </ListItemIcon>
                <ListItemText primary="ユーザ" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </div>
        ) : (
          <div></div>
        )}
        <ListItem key="Docs" disablePadding sx={{ display: "block" }}>
          <ListItemButton
            href="/docs/"
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText
              primary="ドキュメント"
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
        {userId && (
          <ListItem key="Form" disablePadding sx={{ display: "block" }}>
            <ListItemButton
              href={
                "https://docs.google.com/forms/d/e/1FAIpQLScaphtOP-mVpRny223PdPlPqTtYmNoKai4-2UIVbooqu22V9g/viewform?usp=pp_url&entry.2119999098=" +
                userId
              }
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              target="_blank"
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <ViewListIcon />
              </ListItemIcon>
              <ListItemText
                primary="不具合報告フォーム"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Drawer>
  );
}

const MemoDrawer = memo(TemporaryDrawer);

export default MemoDrawer;
