import { Alert } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
const ProcValidation = () => {
  const username = useSelector((state: RootState) => state.user.username);
  const navigate = useNavigate();

  useEffect(() => {
    if (username) {
      navigate("/");
    }
    ReactGA.send("pageview");
    document.title = "Proc-Ranking";
  });

  return (
    <Alert severity="error">
      MicomProcedureユーザであることを確認できませんでした。ログインするアカウントを間違えていませんか？
    </Alert>
  );
};

export default ProcValidation;
