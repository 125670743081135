import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormGroup,
  Box,
  Typography,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { BasePermissions, TokenPermissions } from "../../redux/userSlice";
import TokenPermissionCheckbox from "./PermissionCheckbox";
import { useEffect, useState } from "react";

export interface CRUDMessageRecord {
  permission: BasePermissions;
  message: string;
  isAdminRequired?: boolean;
}

export const TokenPermissionSelect = (props: {
  title: string;
  record: CRUDMessageRecord[];
  target: Set<BasePermissions>;
  userRole: string;
}) => {
  const manageRecord = props.record
    .filter((v) => v.permission === BasePermissions.Manage)
    ?.at(0);
  const otherRecords = props.record.filter(
    (v) => v.permission !== BasePermissions.Manage,
  );

  const [manageChecked, setManageChecked] = useState(false);

  return (
    <FormGroup>
      <Box
        margin="10px"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
      >
        <Typography>{props.title}</Typography>
        <TokenPermissionCheckbox
          target={props.target}
          permission={BasePermissions.Manage}
          onChanged={setManageChecked}
        >
          {manageRecord ? manageRecord.message : ""}
        </TokenPermissionCheckbox>

        <Box
          pl={2}
          display="flex"
          flexDirection="column"
          alignItems="left"
          style={{ marginBottom: "-8px" }}
        >
          {otherRecords.map((v) => {
            return (
              <TokenPermissionCheckbox
                target={props.target}
                permission={v.permission}
                disabled={
                  (v.isAdminRequired ? props.userRole !== "admin" : false) ||
                  manageChecked
                }
                key={props.title + "-" + v.permission}
              >
                {v.message}
              </TokenPermissionCheckbox>
            );
          })}
        </Box>
      </Box>
    </FormGroup>
  );
};

export const TokenPermissionSelectDialog = (props: {
  tokenPermission: TokenPermissions;
  open: boolean;
  userRole: string;
  handleCloseDialog: React.MouseEventHandler<HTMLButtonElement>;
  handleGenerate: React.MouseEventHandler<HTMLButtonElement>;
  handleStateChange: (name: string, permission: TokenPermissions) => void;
}) => {
  const [tokenName, setTokenName] = useState("");
  useEffect(() => {
    props.handleStateChange(tokenName, props.tokenPermission);
  }, [tokenName, props]);

  return (
    <Dialog open={props.open}>
      <DialogTitle>トークンの権限設定</DialogTitle>
      <DialogContent>
        <Typography>トークンの名前</Typography>
        <TextField
          id="token-name"
          label="Name"
          variant="standard"
          onChange={(e) => setTokenName(e.target.value)}
          helperText={
            tokenName === "" ? "トークン名は空にすることはできません" : ""
          }
          error={tokenName === ""}
        />
        <TokenPermissionSelect
          title="データストア"
          target={props.tokenPermission.dstore}
          record={[
            {
              permission: BasePermissions.Manage,
              message: "データストアに関する全ての権限を付与します.",
            },
            {
              permission: BasePermissions.Create,
              message: "データストアの作成を許可します.",
            },
            {
              permission: BasePermissions.Read,
              message: "データストアのリスト取得を許可します.",
            },
            {
              permission: BasePermissions.Delete,
              message: "データストアの削除を許可します.",
            },
          ]}
          userRole={props.userRole}
        />
        <TokenPermissionSelect
          title="データ"
          target={props.tokenPermission.storedata}
          record={[
            {
              permission: BasePermissions.Manage,
              message: "データに関する全ての権限を付与します.",
            },
            {
              permission: BasePermissions.Create,
              message: "データの作成を許可します.",
            },
            {
              permission: BasePermissions.Read,
              message: "データの取得を許可します.",
            },
            {
              permission: BasePermissions.Update,
              message: "データの更新を許可します.",
            },
            {
              permission: BasePermissions.Delete,
              message: "データの削除を許可します.",
            },
          ]}
          userRole={props.userRole}
        />
        <TokenPermissionSelect
          title="ニュース"
          target={props.tokenPermission.news}
          record={[
            {
              permission: BasePermissions.Manage,
              message: "ニュースに関する全ての権限を付与します.",
            },
            {
              permission: BasePermissions.Create,
              message: "ニュースの作成を許可します. (Admin限定)",
              isAdminRequired: true,
            },
            {
              permission: BasePermissions.Read,
              message: "ニュースの取得を許可します.",
            },
            {
              permission: BasePermissions.Update,
              message: "ニュースの更新を許可します. (Admin限定)",
              isAdminRequired: true,
            },
            {
              permission: BasePermissions.Delete,
              message: "ニュースの削除を許可します. (Admin限定)",
              isAdminRequired: true,
            },
          ]}
          userRole={props.userRole}
        />
        <TokenPermissionSelect
          title="ユーザ"
          target={props.tokenPermission.user}
          record={[
            {
              permission: BasePermissions.Manage,
              message: "ユーザに関する全ての権限を付与します.",
            },
            {
              permission: BasePermissions.Create,
              message: "ユーザの作成を許可します. (Admin限定)",
              isAdminRequired: true,
            },
            {
              permission: BasePermissions.Read,
              message: "ユーザの取得を許可します.",
            },
            {
              permission: BasePermissions.Update,
              message: "ユーザの更新を許可します. (Admin限定)",
              isAdminRequired: true,
            },
            {
              permission: BasePermissions.Delete,
              message: "ユーザの削除を許可します. (Admin限定)",
              isAdminRequired: true,
            },
          ]}
          userRole={props.userRole}
        />
        <TokenPermissionSelect
          title="トークン"
          target={props.tokenPermission.token}
          record={[
            {
              permission: BasePermissions.Manage,
              message: "トークンに関する全ての権限を付与します.",
            },
            {
              permission: BasePermissions.Create,
              message: "トークンの作成を許可します.",
            },
            {
              permission: BasePermissions.Delete,
              message: "トークンの削除を許可します.",
            },
          ]}
          userRole={props.userRole}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCloseDialog}>閉じる</Button>
        <Button
          onClick={props.handleGenerate}
          variant="contained"
          disabled={tokenName === ""}
        >
          生成
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TokenPermissionSelectDialog;
