import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/webpack-resolver";
import ReactGA from "react-ga4";

type PatchDialogProps = {
  open: boolean;
  onClose: () => void;
  data: any; // 編集するデータ
  onSave: (data: any) => void; // 編集後のデータを保存するための関数
};
const PatchDialog: React.FC<PatchDialogProps> = ({
  open,
  onClose,
  data,
  onSave,
}) => {
  const [editorValue, setEditorValue] = React.useState(
    JSON.stringify(data, null, 2),
  );

  React.useEffect(() => {
    setEditorValue(JSON.stringify(data, null, 2));
  }, [data]);

  const handleSave = () => {
    try {
      ReactGA.event("patch-data");
      const editedData = JSON.parse(editorValue);
      onSave(editedData);
      onClose();
    } catch (error) {
      alert("Invalid JSON");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit Data</DialogTitle>
      <DialogContent>
        <AceEditor
          mode="json"
          theme="github"
          value={editorValue}
          onChange={setEditorValue}
          name="UNIQUE_ID_OF_DIV"
          editorProps={{ $blockScrolling: true }}
          width="100%"
          height="400px"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PatchDialog;
