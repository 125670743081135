import {
  Box,
  Typography,
  AlertColor,
  IconButton,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import { BasePermissions, TokenPermissions } from "../../redux/userSlice";
import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import customSnackbarSlice from "../../redux/customSnackbarSlice";
import { RootState } from "../../redux/store";
import React from "react";

import KeyIcon from "@mui/icons-material/Key";
import CopyToClipBoard from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TokenPermissionSelectDialog from "./PermissionSelectDialog";
import ReactGA from "react-ga4";

const TokenSetting = () => {
  const userTokens = useSelector((state: RootState) => state.user.tokens);
  const userRole = useSelector((state: RootState) => state.user.role);

  const [newTokenName, setNewTokenName] = useState("");
  const [deleteTokenDialogOpen, setDeleteTokenDialogOpen] = useState(false);
  const openTokenDeletionDialog = () => setDeleteTokenDialogOpen(true);
  const closeTokenDeletionDialog = () => setDeleteTokenDialogOpen(false);

  const [createdDialogOpen, setCreatedDialogOpen] = useState(false);
  const openCreatedDialog = () => setCreatedDialogOpen(true);
  const closeCreatedDialog = () => setCreatedDialogOpen(false);

  const [deletingToken, setDeletingToken] = useState("");
  const [newJWT, setNewJWT] = useState("");
  const [openTip, setOpenTip] = useState(false);

  const dispatch = useDispatch();
  const snackbarConfigure = (type: AlertColor, msg: string) =>
    dispatch(
      customSnackbarSlice.actions.configure({ type: type, message: msg }),
    );

  const [permDialogOpen, setPermDialogOpen] = useState(false);

  const [tokenPermission, setTokenPermission] = useState({
    dstore: new Set<BasePermissions>(),
    storedata: new Set<BasePermissions>(),
    news: new Set<BasePermissions>(),
    user: new Set<BasePermissions>(),
    token: new Set<BasePermissions>(),
  } as TokenPermissions);

  const [tokenList, setTokenList] = useState([] as JSX.Element[]);

  const confirmTokenDeletion = useCallback((name: string) => {
    setDeletingToken(name);
    openTokenDeletionDialog();
  }, []);

  const genTokenCard = useCallback(
    (name: string, permissions: TokenPermissions) => {
      return (
        <Card key={name} sx={{ m: 2 }}>
          <CardContent>
            <Box
              justifyContent="flex-start"
              flexDirection="row"
              display="flex"
              gap="10px"
            >
              <KeyIcon />
              <Typography align="left">{name}</Typography>
              <Typography
                align="left"
                color={"GrayText"}
                sx={{ whiteSpace: "pre-wrap" }}
              >
                {Object.keys(permissions)
                  .filter(
                    (key) =>
                      Array.from(permissions[key as keyof TokenPermissions])
                        .length !== 0,
                  )
                  .map((key) => {
                    return (
                      key +
                      ": " +
                      Array.from(
                        permissions[key as keyof TokenPermissions],
                      ).join(", ")
                    );
                  })
                  .join("\n")}
              </Typography>
            </Box>
          </CardContent>
          <CardActions disableSpacing>
            <Button
              variant="contained"
              color="error"
              onClick={() => confirmTokenDeletion(name)}
            >
              削除
            </Button>
          </CardActions>
        </Card>
      );
    },
    [confirmTokenDeletion],
  );

  const initTokenCards = useCallback(() => {
    setTokenList(
      userTokens?.map((v, _, arr) => {
          return genTokenCard(v.name, v.permissions);
        }),
    );
  }, [userTokens, genTokenCard]);

  useEffect(initTokenCards, [userTokens, genTokenCard, initTokenCards]);

  const onClickGenButton = () => {
    if (
      tokenList?.map((v) => v.key).some((v) => v === newTokenName) ||
      newTokenName === ""
    ) {
      snackbarConfigure(
        "error",
        "そのトークン名はすでに使用されているため生成できません",
      );
      return;
    }

    let permissions: { [key: string]: BasePermissions[] } = {};
    const keys = Object.keys(
      tokenPermission,
    ) as (keyof typeof tokenPermission)[];
    for (const key of keys) {
      permissions[key] = Array.from(tokenPermission[key]);
    }

    fetch("/apptokens", {
      method: "POST",
      body: JSON.stringify({ name: newTokenName, permissions: permissions }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (Object.keys(res).some((v) => v === "message")) {
          throw new Error(res.message);
        }
        setNewTokenName(res.name);
        setNewJWT(res.token);
        setTokenList(
          (tokenList ?? []).concat(genTokenCard(res.name, res.permissions)),
        );
        openCreatedDialog();
        ReactGA.event("create-token");
      })
      .catch((reason) => {
        snackbarConfigure("error", reason);
      });
  };

  const executeTokenDeletion = () => {
    if (!deletingToken) {
      snackbarConfigure(
        "error",
        "削除しようとしているトークンが見つかりません",
      );
      return;
    }
    fetch("/apptokens/" + deletingToken, {
      method: "DELETE",
      body: JSON.stringify({ name: deletingToken }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      setTokenList(tokenList.filter((v) => v.key !== deletingToken));
      snackbarConfigure("success", "トークンを削除しました");
      ReactGA.event("delete-token");
    });
    closeTokenDeletionDialog();
  };

  const TokenDeletionConfirmDialog = () => {
    return (
      <Dialog open={deleteTokenDialogOpen} onClose={closeTokenDeletionDialog}>
        <DialogTitle id="delete-dialog-title">
          トークン {deletingToken} を本当に削除しますか？
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-text">
            一度トークンを削除すると同じトークンは発行できません。(同じ名前で内容が異なるトークンは発行できます。)
            本当に実行しますか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeTokenDeletionDialog} autoFocus>
            いいえ
          </Button>
          <Button onClick={executeTokenDeletion}>はい</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const TokenCreatedDialog = () => {
    return (
      <Dialog open={createdDialogOpen} onClose={closeCreatedDialog}>
        <DialogTitle>トークン {newTokenName} が生成されました</DialogTitle>
        <DialogContent>
          <DialogContentText>
            以下が新しく生成されたトークンです。これを閉じると再生成できないので慎重に保管してください。
          </DialogContentText>
          <TextField
            InputProps={{ readOnly: true }}
            defaultValue={newJWT}
            fullWidth
            inputProps={{
              endadornment: (
                <InputAdornment position="end">
                  <Tooltip
                    arrow
                    open={openTip}
                    onClose={() => setOpenTip(false)}
                    disableHoverListener
                    placement="top"
                    title="Copied!"
                  >
                    <CopyToClipBoard text={newJWT}>
                      <IconButton onClick={() => setOpenTip(true)}>
                        <ContentCopyIcon />
                      </IconButton>
                    </CopyToClipBoard>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          ></TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCreatedDialog}>閉じる</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Box margin="10px">
      <Button variant="contained" onClick={() => setPermDialogOpen(true)}>
        生成する
      </Button>
      {tokenList}
      <TokenPermissionSelectDialog
        tokenPermission={tokenPermission}
        userRole={userRole}
        handleCloseDialog={() => {
          setPermDialogOpen(false);
        }}
        handleGenerate={() => {
          setPermDialogOpen(false);
          onClickGenButton();
        }}
        open={permDialogOpen}
        handleStateChange={(name: string, permission: TokenPermissions) => {
          setNewTokenName(name);
          // setTokenPermission(permission);
        }}
      />
      <TokenDeletionConfirmDialog />
      <TokenCreatedDialog />
    </Box>
  );
};

export default TokenSetting;
