import { Box, FormControlLabel, Checkbox, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { BasePermissions } from "../../redux/userSlice";

export const TokenPermissionCheckbox = (props: {
  target: Set<BasePermissions>;
  permission: BasePermissions;
  disabled?: boolean;
  onChanged?: (b: boolean) => void;
  children: string;
}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (checked) {
      props.target.add(props.permission);
    } else {
      props.target.delete(props.permission);
    }
  }, [checked, props.target, props.permission]);

  const [isDisabled, setIsDisabled] = useState(
    props.disabled || props.target.has(BasePermissions.Manage),
  );
  useEffect(() => {
    // console.log(props.disabled, props.target);
    setIsDisabled(props.disabled || props.target.has(BasePermissions.Manage));
  }, [props.disabled, props.target]);

  return (
    <Box
      pl={2}
      display="flex"
      flexDirection="row"
      alignItems="center"
      style={{ marginBottom: "-8px" }}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={(e: any, c: boolean) => {
              setChecked(c);
              if (props.onChanged) props.onChanged(c);
            }}
            disabled={isDisabled}
          />
        }
        label={
          props.permission
            ? props.permission.charAt(0).toUpperCase() +
              props.permission.slice(1)
            : ""
        }
      />
      <Typography color="GrayText">{props.children}</Typography>
    </Box>
  );
};

export default TokenPermissionCheckbox;
