import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import ThemeProvider from "@mui/private-theming/ThemeProvider";
import { createTheme } from "@mui/material/styles";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import CreateStore from "./components/CreateStore";
import { Box } from "@mui/material";
import ShowStore from "./components/ShowStore";
import Settings from "./components/setting/Setting";
import ProcValidation from "./components/ProcValidation";
import MakeNews from "./components/admin/MakeNews";
import ShowUsers from "./components/admin/ShowUsers";
import LoggedOut from "./components/LoggedOut";

import { styled } from "@mui/material/styles";
import CustomSnackbar from "./components/CustomSnackbar";
import MemoAppBar from "./components/Appbar";
import MemoDrawer from "./components/Drawer";
import { useEffect } from "react";

import ReactGA from "react-ga4";

const theme = createTheme({
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

function App() {
  useEffect(() => {
    ReactGA.initialize("G-024CF1HCM0");
  }, []);

  return (
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <div className="App">
            <Box sx={{ display: "flex" }}>
              <MemoAppBar />
              <MemoDrawer />
              <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                <CustomSnackbar />
                <Routes>
                  <Route path="/create" element={<CreateStore />} />
                  <Route path="/store/:id" Component={ShowStore} />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/rejected" element={<ProcValidation />} />
                  <Route path="/logged_out" element={<LoggedOut />} />
                  <Route path="/admin/news" Component={MakeNews} />
                  <Route path="/admin/users" Component={ShowUsers} />
                  <Route path="/*" element={<Home />} />
                </Routes>
              </Box>
            </Box>
          </div>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
