import { useEffect, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  type MRT_ColumnOrderState,
  type MRT_TableInstance,
  type MRT_RowSelectionState,
} from "material-react-table";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  QueryClient,
  QueryClientProvider,
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"; //note: this is TanStack React Query V5
import EditIcon from "@mui/icons-material/Edit";
import PatchDialog from "./PatchDialog";
import ImportButton from "./ImportButton";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { saveAs } from "file-saver";

import { Buffer } from "buffer";
import DeleteIcon from "@mui/icons-material/Delete";
import { format, parseISO, isValid } from "date-fns";
import { toDate, zonedTimeToUtc } from "date-fns-tz";

//Your API response shape will probably be different. Knowing a total row count is important though.
type ApiResponse = Array<RankingDocument>;

type RankingDocument = {
  _id: string;
  createdAt: string;
  updatedAt: string;
};

// const columnNames = {
//   firstName: 'First Name',
//   lastName: 'Last Name',
//   address: 'Address',
//   state: 'State',
//   phoneNumber: 'Phone Number',
//   lastLogin: 'Last Login',
// } as const;

const MRTDatagrid = (props: { id: string; readonly?: boolean }) => {
  const isReadonly = props.readonly;
  const [rowCount, setRowCount] = useState(0);
  //manage our own state for stuff we want to pass to the API
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    [],
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  //if using dynamic columns that are loaded after table instance creation, we will need to manage the column order state ourselves
  //UPDATE: No longer needed as of v2.10.0
  const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>([]);

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [currentEditData, setCurrentEditData] = useState({});
  const [currentEditId, setCurrentEditId] = useState("");

  const handleEditClick = (data: any) => {
    const { _id, createdAt, updatedAt, ...editData } = data;
    setCurrentEditData(editData);
    setCurrentEditId(_id);
    setEditDialogOpen(true);
  };

  const handleSaveEditedData = (data: any) => {
    setEditDialogOpen(false);
    updateData({
      ...data,
      _id: currentEditId,
      updatedAt: new Date().toISOString(),
    });
  };

  const { mutateAsync: updateData } = useUpdateData();

  function useUpdateData() {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: async (dataContainingId: any) => {
        const { _id, ...data } = dataContainingId;
        await fetch("/dstore/" + props.id + "/" + currentEditId, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
      },
      //client side optimistic update
      onMutate: (newData: any) => {
        queryClient.setQueryData(
          [
            "table-data",
            columnFilters, //refetch when columnFilters changes
            globalFilter, //refetch when globalFilter changes
            pagination.pageIndex, //refetch when pagination.pageIndex changes
            pagination.pageSize, //refetch when pagination.pageSize changes
            sorting, //refetch when sorting changes
          ],
          (prevData: any) => {
            return prevData?.map((prevData: any) =>
              prevData._id === newData._id ? newData : prevData,
            );
          },
        );
      },
      onSettled: () =>
        queryClient.invalidateQueries({ queryKey: ["table-data"] }), //refetch users after mutation, disabled for demo
    });
  }

  //consider storing this code in a custom hook (i.e useFetchUsers)
  const {
    data = [], //your data and api response will probably be different
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<ApiResponse>({
    queryKey: [
      "table-data",
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = new URL(
        "/dstore/" + props.id,
        // "http://localhost:3000"
        process.env.NODE_ENV === "production"
          ? "https://ranking.micomprocedure.com"
          : "http://localhost:3000",
      );
      //read our state and pass it to the API as query params
      fetchURL.searchParams.set(
        "skip",
        `${pagination.pageIndex * pagination.pageSize}`,
      );
      fetchURL.searchParams.set("limit", `${pagination.pageSize}`);
      // fetchURL.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      //   fetchURL.searchParams.set('globalFilter', globalFilter ?? '');
      if (sorting.length > 0) {
        fetchURL.searchParams.set("sort", sorting[0].id);
        if (sorting[0].desc) {
          fetchURL.searchParams.set("direction", "desc");
        }
      }

      //use whatever fetch library you want, fetch, axios, etc
      const response = await fetch(fetchURL.href);
      try {
        setRowCount(
          parseInt(response.headers.get("X-RankingDataCount") ?? "0"),
        );
      } catch {
        console.error("Cannot get rowcount");
        setRowCount(0);
      }
      const json = (await response.json()) as ApiResponse;
      return json as unknown as ApiResponse;
      // .map((v) => {
      //   return {
      //     ...v,
      //     // createdAt: format(parseISO(v.createdAt), "yyyy/MM/dd HH:mm:ss"),
      //     // updatedAt: format(parseISO(v.updatedAt), "yyyy/MM/dd HH:mm:ss"),
      //   };
      // })
    },
    placeholderData: keepPreviousData, //don't go to 0 rows when refetching or paginating to next page
  });

  const columnsHeaderMap: { [key: string]: string } = {
    _id: "ObjectID",
    createdAt: "Created At",
    updatedAt: "Updated At",
  };

  const { data: columns } = useQuery<MRT_ColumnDef<RankingDocument>[]>({
    queryKey: ["columnsquery", data],
    queryFn: async () => {
      if (!data.length) return [];
      const response = await fetch("/dstore/" + props.id + "/columns");
      if (response.ok) {
        const json = (await response.json()) as string[];
        const col = json.map((columnId) => {
          if (columnId === "createdAt" || columnId === "updatedAt") {
            return {
              header: columnsHeaderMap[columnId],
              accessorKey: columnId,
              id: columnId,
              Cell: ({ cell }) => {
                if (!isValid(toDate(cell.getValue<string>()))) {
                  return "Failed to parse";
                }
                return format(
                  parseISO(cell.getValue<string>()),
                  "yyyy/MM/dd HH:mm:ss",
                );
              },
            } as MRT_ColumnDef<RankingDocument>;
          } else {
            return {
              header: Object.keys(columnsHeaderMap).includes(columnId)
                ? columnsHeaderMap[columnId]
                : columnId,
              accessorKey: columnId,
              id: columnId,
            } as MRT_ColumnDef<RankingDocument>;
          }
        });
        setColumnOrder([
          "mrt-row-actions",
          "mrt-row-select",
          ...(col ?? [])
            .map((column) => column.id!)
            .sort()
            .sort(sortFn),
        ]);
        return col;
      } else {
        return [];
      }
    },
  });

  const handleExport = (table: MRT_TableInstance<RankingDocument>) => {
    const now = new Date();
    new Promise(async (resolve) => {
      if (table.getIsSomeRowsSelected()) {
        resolve(
          table.getSelectedRowModel().rows.map((v) => v.original) as any[],
        );
      } else {
        const fetchURL = new URL(
          "/dstore/" + props.id,
          // "http://localhost:3000"
          process.env.NODE_ENV === "production"
            ? "https://ranking.micomprocedure.com"
            : "http://localhost:3000",
        );
        if (sorting.length > 0) {
          fetchURL.searchParams.set("sort", sorting[0].id);
          if (sorting[0].desc) {
            fetchURL.searchParams.set("direction", "desc");
          }
        }
        resolve(
          await fetch(fetchURL.href)
            .then((res) => res.json())
            .then((json) => json as any[]),
        );
      }
    })
      .then((rows) => {
        return (rows as any[]).map((v) => {
          return {
            ...v,
            createdAt: zonedTimeToUtc(v.createdAt, "Asia/Tokyo"),
            updatedAt: zonedTimeToUtc(v.updatedAt, "Asia/Tokyo"),
          };
        });
      })
      .then((rows) => {
        saveAs(
          new Blob([Buffer.from(JSON.stringify(rows as any[], undefined, 2))]),
          "ProcRaExport_" +
            format(now, "yyyyMMddHHmmss") +
            "_" +
            props.id +
            ".json",
        );
      });
  };

  const handleDelete = (table: MRT_TableInstance<RankingDocument>) => {
    const ids = table.getSelectedRowModel().rows?.map((v) => v.original?._id);
    fetch("/dstore/" + props.id + "/bulkdelete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ids: ids }),
    }).then(() => refetch());
  };

  const sortFn = (a: string, b: string) => {
    if (b === "_id") return 1;
    if (a === "_id") return -1;
    if (b === "updatedAt") return -1;
    if (a === "updatedAt") return 1;
    if (b === "createdAt") return -1;
    if (a === "createdAt") return 1;
    return 0;
  };

  // //UPDATE: No longer needed as of v2.10.0
  // useEffect(() => {
  //   //if using dynamic columns that are loaded after table instance creation, we will need to set the column order state ourselves
  //   setColumnOrder([
  //     "mrt-row-actions",
  //     "mrt-row-select",
  //     ...(columns ?? [])
  //       .map((column) => column.id!)
  //       .sort()
  //       .sort(sortFn),
  //   ]);
  //   console.log(columns, columnOrder);
  // }, [columns]);

  const table = useMaterialReactTable({
    columns: columns ?? [],
    data,
    initialState: {
      showColumnFilters: true,
      sorting: [{ id: "updatedAt", desc: true }],
    },
    manualFiltering: true, //turn off built-in client-side filtering
    manualPagination: true, //turn off built-in client-side pagination
    manualSorting: true, //turn off built-in client-side sorting
    enableMultiSort: false,
    enableGlobalFilter: false, //give loading spinner somewhere to go while loading
    muiTableBodyProps: {
      children: isLoading ? (
        <tr style={{ height: "200px" }}>
          <td />
        </tr>
      ) : undefined,
    },
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    editDisplayMode: "modal",
    enableEditing: !isReadonly,
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => handleEditClick(row.original)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    onColumnFiltersChange: setColumnFilters,
    onColumnOrderChange: setColumnOrder,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    enableFullScreenToggle: false,
    enableRowSelection: true,
    renderTopToolbarCustomActions: (props: {
      table: MRT_TableInstance<RankingDocument>;
    }) => (
      <Box justifyContent="space-between">
        <Stack direction="row">
          <Tooltip arrow title="Refresh Data">
            <IconButton onClick={() => refetch()}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          {isReadonly ? (
            <></>
          ) : (
            <>
              <Tooltip arrow title="Upload Data">
                <ImportButton onSuccess={() => refetch()} />
              </Tooltip>
              <Tooltip arrow title="Download Data">
                <IconButton onClick={() => handleExport(props.table)}>
                  <FileDownloadIcon />
                </IconButton>
              </Tooltip>
              <Tooltip arrow title="Delete Data">
                <span>
                  <IconButton
                    onClick={() => handleDelete(props.table)}
                    disabled={
                      !(
                        props.table.getIsSomeRowsSelected() ||
                        props.table.getIsAllRowsSelected()
                      )
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </>
          )}
        </Stack>
      </Box>
    ),
    rowCount,
    state: {
      columnFilters,
      columnOrder,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return (
    <div>
      <MaterialReactTable table={table} />
      <PatchDialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        data={currentEditData}
        onSave={handleSaveEditedData}
      ></PatchDialog>
    </div>
  );
};

const queryClient = new QueryClient();

const MRTDatagridWithQueryProvider = (props: {
  id: string;
  readonly?: boolean;
}) => (
  //App.tsx or AppProviders file. Don't just wrap this component with QueryClientProvider! Wrap your whole App!
  <QueryClientProvider client={queryClient}>
    <MRTDatagrid id={props.id} readonly={props.readonly} />
  </QueryClientProvider>
);

export default MRTDatagridWithQueryProvider;
