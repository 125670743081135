import {
  Box,
  Typography,
  Divider,
  Button,
  Card,
  CardActions,
  CardContent,
  Paper,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  AlertColor,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { NewsDoc } from "../News";
import customSnackbarSlice from "../../redux/customSnackbarSlice";
import ReactGA from "react-ga4";

const MakeNews = () => {
  const username = useSelector((state: RootState) => state.user.username);
  const [deleteNewsDialogOpen, setDeleteNewsDialogOpen] = useState(false);
  const openNewsDeletionDialog = () => setDeleteNewsDialogOpen(true);
  const closeNewsDeletionDialog = () => setDeleteNewsDialogOpen(false);
  const [deletingNewsId, setDeletingNewsId] = useState("");
  const [news, setNews] = useState([] as NewsDoc[]);

  const [creatingNewsTitle, setCreatingNewsTitle] = useState("");
  const [creatingNewsAuthor, setCreatingNewsAuthor] = useState(username);
  const [creatingNewsContent, setCreatingNewsContent] = useState("");

  const dispatch = useDispatch();

  const snackbarConfigure = (type: AlertColor, msg: string) =>
    dispatch(
      customSnackbarSlice.actions.configure({ type: type, message: msg }),
    );

  const confirmNewsDeletion = useCallback((name: string) => {
    setDeletingNewsId(name);
    openNewsDeletionDialog();
  }, []);

  const initNewsCards = () => {
    setNewsList(
      news?.map((v, _, arr) => {
        return genNewsCard(v);
      }),
    );
  };

  useEffect(() => {
    fetch("/news")
      .then((res) => res.json())
      .then((v: NewsDoc[]) => setNews(v));
    ReactGA.send("pageview");
    document.title = "ProcRa | [Admin]News";
  }, []);

  const genNewsCard = useCallback(
    (v: NewsDoc) => {
      return (
        <Card key={v._id}>
          <CardContent>
            <Box
              justifyContent="flex-start"
              flexDirection="column"
              display="flex"
              gap="10px"
            >
              <AnnouncementIcon />
              <Box justifyContent="flex-start" flexDirection="column">
                <Typography align="left" component="h5">
                  {v.title}
                </Typography>
                <Typography align="right">ID: {v._id}</Typography>
                <Typography align="right">Author: {v.author}</Typography>
                <Typography align="left" component="p" whiteSpace="pre-line">
                  {decodeURIComponent(v.content)}
                </Typography>
              </Box>
              <Divider />
            </Box>
          </CardContent>
          <CardActions disableSpacing>
            <Button
              variant="contained"
              color="error"
              onClick={() => confirmNewsDeletion(v._id)}
            >
              削除
            </Button>
          </CardActions>
        </Card>
      );
    },
    [confirmNewsDeletion],
  );

  useEffect(initNewsCards, [news, genNewsCard]);
  const [newsList, setNewsList] = useState([] as JSX.Element[]);
  const NewsDeletionConfirmDialog = () => {
    return (
      <Dialog open={deleteNewsDialogOpen} onClose={closeNewsDeletionDialog}>
        <DialogTitle id="delete-dialog-title">
          News {deletingNewsId} を本当に削除しますか？
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-text">
            本当に実行しますか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeNewsDeletionDialog} autoFocus>
            いいえ
          </Button>
          <Button onClick={executeNewsDeletion}>はい</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const executeNewsDeletion = () => {
    if (!deletingNewsId) {
      snackbarConfigure("error", "削除しようとしているNewsが見つかりません");
      return;
    }
    fetch("/news/" + deletingNewsId, {
      method: "DELETE",
      body: JSON.stringify({ name: deletingNewsId }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      setNewsList(newsList.filter((v) => v.key !== deletingNewsId));
      snackbarConfigure("success", "Newsを削除しました");
    });
    closeNewsDeletionDialog();
  };

  const onClickGenButton = () => {
    fetch("/news", {
      method: "POST",
      body: JSON.stringify({
        title: creatingNewsTitle,
        author: creatingNewsAuthor,
        content: encodeURIComponent(creatingNewsContent),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (Object.keys(res).some((v) => v === "message")) {
          throw new Error(res.message);
        }
        setNewsList(newsList.concat(genNewsCard(res)));
        snackbarConfigure("success", "Newsの作成に成功しました");
      })
      .catch((reason) => {
        snackbarConfigure("error", reason);
      });
  };

  return (
    <Box margin="10px" alignContent="flex-start" gap="20px">
      <Typography variant="h5" align="left">
        News作成
      </Typography>
      <Divider />
      <Paper>
        <Box
          margin="10px"
          padding="10px"
          gap="10px"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
        >
          <TextField
            variant="standard"
            defaultValue={username}
            label="Author"
            onChange={(ev) => setCreatingNewsAuthor(ev.target.value)}
          ></TextField>
          <TextField
            variant="standard"
            label="Title"
            onChange={(ev) => setCreatingNewsTitle(ev.target.value)}
          ></TextField>
        </Box>
        <Box
          sx={{
            "& .MuiTextField-root": { m: 1, width: "50ch" },
          }}
        >
          <TextField
            variant="outlined"
            label="Contents"
            multiline
            onChange={(ev) => setCreatingNewsContent(ev.target.value)}
          ></TextField>
          <Button variant="outlined" onClick={onClickGenButton}>
            生成
          </Button>
        </Box>
      </Paper>
      <Divider />
      <Typography variant="h5" align="left">
        News管理
      </Typography>
      <Divider />
      {newsList ?? <Typography component="p">No News</Typography>}
      <NewsDeletionConfirmDialog />
    </Box>
  );
};

export default MakeNews;
