import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import customSnackbarSlice from "../../redux/customSnackbarSlice";
import {
  AlertColor,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";

import StorageIcon from "@mui/icons-material/Storage";
import userSlice from "../../redux/userSlice";
import ReactGA from "react-ga4";

const StoreSetting = () => {
  const userStores = useSelector((state: RootState) => state.user.stores);

  const dispatch = useDispatch();

  const [deleteStoreDialogOpen, setDeleteStoreDialogOpen] = useState(false);
  const openStoreDeletionDialog = () => setDeleteStoreDialogOpen(true);
  const closeStoreDeletionDialog = () => setDeleteStoreDialogOpen(false);

  const [deletingStore, setDeletingStore] = useState("");

  const setDrawerStores = (value: string[]) =>
    dispatch(userSlice.actions.setStores(value));
  const snackbarConfigure = (type: AlertColor, msg: string) =>
    dispatch(
      customSnackbarSlice.actions.configure({ type: type, message: msg }),
    );

  const [storeList, setStoreList] = useState([] as JSX.Element[]);

  const initStoreCards = () => {
    const promiseLists = userStores?.map((v, _, arr) => {
      return genStoreCard(v);
    });

    Promise.all(promiseLists).then((v) => setStoreList(v));
  };

  const confirmStoreDeletion = useCallback((name: string) => {
    setDeletingStore(name);
    openStoreDeletionDialog();
  }, []);

  const genStoreCard = useCallback(
    async (name: string) => {
      let columns: string[] = [];
      let count: string = "0";

      await fetch("/dstore/" + name + "/columns")
        .then((res) => {
          count = res.headers.get("x-rankingdatacount") ?? "Unknown";
          return res.json();
        })
        .then((v) => {
          columns = v;
        });

      return (
        <Card key={name} sx={{ m: 2 }}>
          <CardContent>
            <Box
              justifyContent="flex-start"
              flexDirection="row"
              display="flex"
              gap="10px"
            >
              <StorageIcon />
              <Typography align="left">{name}</Typography>
              <Typography align="left" color="GrayText">
                カラム: {columns.join(", ")}
                <br />
                データ数: {count}
              </Typography>
            </Box>
          </CardContent>
          <CardActions disableSpacing>
            <Button
              variant="contained"
              color="error"
              onClick={() => confirmStoreDeletion(name)}
            >
              削除
            </Button>
          </CardActions>
        </Card>
      );
    },
    [confirmStoreDeletion],
  );

  useEffect(initStoreCards, [userStores, genStoreCard]);

  const executeStoreDeletion = () => {
    if (!deletingStore) {
      snackbarConfigure(
        "error",
        "削除しようとしているデータストアが見つかりません",
      );
      return;
    }
    fetch("/dstore/" + deletingStore, {
      method: "DELETE",
      body: JSON.stringify({ name: deletingStore }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      setStoreList(storeList.filter((v) => v.key !== deletingStore));
      setDrawerStores(userStores.filter((v) => v !== deletingStore));
      snackbarConfigure("success", "データストアを削除しました");
      ReactGA.event("delete-store");
    });
    closeStoreDeletionDialog();
  };

  const StoreDeletionConfirmDialog = () => {
    return (
      <Dialog open={deleteStoreDialogOpen} onClose={closeStoreDeletionDialog}>
        <DialogTitle id="delete-dialog-title">
          データストア {deletingStore} を本当に削除しますか？
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-text">
            データストアを削除する前にデータをエクスポートすることをおすすめします。
            本当に実行しますか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeStoreDeletionDialog} autoFocus>
            いいえ
          </Button>
          <Button onClick={executeStoreDeletion}>はい</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Box margin="10px" gap="5px">
      {storeList.length > 0 ? (
        storeList
      ) : (
        <Typography variant="body1">データストアが存在しません</Typography>
      )}
      <StoreDeletionConfirmDialog />
    </Box>
  );
};

export default StoreSetting;
