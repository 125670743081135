import {
  Alert,
  Box,
  Button,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useEffect, useState } from "react";
import userSlice from "../redux/userSlice";
import ReactGA from "react-ga4";
const CreateStore = () => {
  useEffect(() => {
    ReactGA.send("pageview");
    document.title = "ProcRa | Create Store";
  });

  const username = useSelector((state: RootState) => state.user.username);
  const stores = useSelector((state: RootState) => state.user.stores);
  // const [storeName, setStoreName] = useState("");
  // const [createSuccess, setCreateSuccess] = useState(false);
  // const [createFailed, setCreateFailed] = useState(false);

  const dispatch = useDispatch();
  const appendStores = (value: string) =>
    dispatch(userSlice.actions.appendStores(value));
  const [creatingStoreName, setCreatingStoreName] = useState("");
  const [creationSuccess, setCreationSuccess] = useState(false);
  const [creationFailed, setCreationFailed] = useState(false);

  const validateName = (name: string) => {
    if (stores?.includes(name)) {
      return false;
    } else if (!name.match(/^[\w.-]+$/)) {
      return false;
    }
    return true;
  };

  const isCharAvailable = (name: string) => {
    if (!name.match(/^[\w-]+$/)) {
      return false;
    }
    return true;
  };

  if (username) {
    return (
      <Box
        sx={{ display: "flex" }}
        flexDirection="column"
        justifyContent="flex-start"
        gap="10px"
        m={2}
      >
        <Typography variant="h5" align="left">
          新規データストアの作成
        </Typography>
        <Divider />
        <Typography align="left">
          新規データストアの名前を入力してください。
          <br />
          使用できる文字は半角英数字、_(アンダーバー)、-(ハイフン)です。
        </Typography>
        <Box
          sx={{ display: "flex" }}
          flexDirection="row"
          justifyContent="flex-start"
          gap="10px"
          alignItems="center"
        >
          <TextField
            id="datastore-name"
            label="名前"
            variant="standard"
            onChange={(ev) => setCreatingStoreName(ev.target.value)}
            error={!!creatingStoreName && !isCharAvailable(creatingStoreName)}
            helperText={
              creatingStoreName && !isCharAvailable(creatingStoreName)
                ? "その名前は使用できません"
                : ""
            }
          />
          <Button
            variant="contained"
            disabled={!validateName(creatingStoreName)}
            onClick={() => {
              setCreationSuccess(false);
              setCreationFailed(false);
              fetch("/dstore", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ storeName: creatingStoreName }),
              }).then((res) => {
                if (res.ok) {
                  setCreationSuccess(true);
                  appendStores(creatingStoreName);
                  ReactGA.event("create-store");
                } else {
                  setCreationFailed(true);
                }
              });
            }}
          >
            作成
          </Button>
        </Box>
        {creationSuccess ? (
          <Alert severity="success">作成しました</Alert>
        ) : (
          <div></div>
        )}
        {creationFailed ? (
          <Alert severity="error">作成に失敗しました</Alert>
        ) : (
          <div></div>
        )}
      </Box>
    );
  } else {
    return (
      <Box margin="10px">
        <Alert severity="error">ログインしてください</Alert>
      </Box>
    );
  }
};

export default CreateStore;
