import { Box, Alert, Typography, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import StoreSetting from "./StoreSetting";
import TokenSetting from "./TokenSetting";
import ReactGA from "react-ga4";
import { useEffect } from "react";

const Settings = () => {
  const username = useSelector((state: RootState) => state.user.username);

  useEffect(() => {
    ReactGA.send("pageview");
    document.title = "ProcRa | Setting";
  });

  if (username) {
    return (
      <Box margin="10px" alignContent="flex-start" gap="20px">
        <Typography variant="h5" align="left">
          データストア
        </Typography>
        <Divider />
        <StoreSetting />
        <Divider />
        <Typography variant="h5" align="left">
          アクセストークン
        </Typography>
        <Divider />
        <TokenSetting />
      </Box>
    );
  } else {
    return (
      <Box margin="10px">
        <Alert severity="error">ログインしてください</Alert>
      </Box>
    );
  }
};

export default Settings;
