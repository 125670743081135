import { Button, Divider, Paper, Typography } from "@mui/material";
import { useState } from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/webpack-resolver";
import { useDispatch } from "react-redux";
import customSnackbarSlice from "../redux/customSnackbarSlice";
import ReactGA from "react-ga4";

interface OperatingAPIProps {
  id: string | undefined;
}

const OperatingAPI = (props: OperatingAPIProps) => {
  const [data, setData] = useState('{\n  "score": 123\n}');

  const dispatch = useDispatch();

  function isJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  function createData() {
    if (props.id === undefined) {
      dispatch(customSnackbarSlice.actions.setType("error"));
      dispatch(customSnackbarSlice.actions.setMessage("エラーが発生しました"));
      dispatch(customSnackbarSlice.actions.setShow(true));
      return;
    }
    if (!isJsonString(data)) {
      dispatch(customSnackbarSlice.actions.setType("error"));
      dispatch(customSnackbarSlice.actions.setMessage("無効なJSONです"));
      dispatch(customSnackbarSlice.actions.setShow(true));
      return;
    }
    fetch("/dstore/" + props.id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    }).then((res) => {
      if (res.ok) {
        dispatch(customSnackbarSlice.actions.setType("info"));
        dispatch(
          customSnackbarSlice.actions.setMessage("データを作成しました"),
        );
        dispatch(customSnackbarSlice.actions.setShow(true));
        ReactGA.event("create-data");
        return;
      } else {
        dispatch(customSnackbarSlice.actions.setType("error"));
        dispatch(
          customSnackbarSlice.actions.setMessage("エラーが発生しました"),
        );
        dispatch(customSnackbarSlice.actions.setShow(true));
        return;
      }
    });
  }

  return (
    <Paper sx={{ textAlign: "left", padding: 2 }}>
      <Typography variant="h5">データを作成</Typography>
      <Divider />
      <AceEditor
        mode="json"
        theme="github"
        onChange={setData}
        name="api-body"
        defaultValue={'{\n  "score": 123\n}'}
        height="100px"
      />
      <Button variant="contained" onClick={createData}>
        実行
      </Button>
    </Paper>
  );
};

export default OperatingAPI;
