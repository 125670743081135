import { createSlice } from "@reduxjs/toolkit";

export enum BasePermissions {
  Manage = "manage",
  Create = "create",
  Read = "read",
  Update = "update",
  Delete = "delete",
}

export interface TokenPermissions {
  dstore: Set<BasePermissions>;
  storedata: Set<BasePermissions>;
  user: Set<BasePermissions>;
  news: Set<BasePermissions>;
  token: Set<BasePermissions>;
}

const userSlice = createSlice({
  name: "user",
  initialState: {
    username: "",
    userId: "",
    icon: "",
    role: "",
    tokens: [] as {
      name: string;
      token: string;
      permissions: TokenPermissions;
    }[],
    stores: [] as string[],
  },
  reducers: {
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setIcon: (state, action) => {
      state.icon = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setTokens: (state, action) => {
      state.tokens = action.payload;
    },
    setStores: (state, action) => {
      state.stores = action.payload;
    },
    appendStores: (state, action) => {
      state.stores.push(action.payload);
    },
    removeStores: (state, action) => {
      state.stores = state.stores.filter((e) => e !== action.payload);
    },
  },
});

export default userSlice;
