import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import customSnackbarSlice from "./customSnackbarSlice";
import appStateSlice from "./appStateSlice";

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["import/setAnchorEl"],
      },
    }),
  reducer: {
    user: userReducer.reducer,
    snackbar: customSnackbarSlice.reducer,
    app: appStateSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
