import { useEffect, useMemo, useState } from "react";

import RefreshIcon from "@mui/icons-material/Refresh";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  type MRT_ColumnOrderState,
} from "material-react-table";
import { Box, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";

import EditIcon from "@mui/icons-material/Edit";
import {
  useQuery,
  keepPreviousData,
  QueryClient,
  QueryClientProvider,
  useQueryClient,
  useMutation,
} from "@tanstack/react-query";
import PatchDialog from "../PatchDialog";

interface User {
  _id: string;
  discord_id: string;
  display_name: string;
  icon: string;
  role: string;
  access_token: string;
  refresh_token: string;
  app_tokens: { name: string; token: string }[];
  having_stores: { id: string; name: string }[];
}

interface GridUser {
  _id: string;
  discord_id: string;
  display_name: string;
  icon: string;
  role: string;
  access_token: string;
  refresh_token: string;
  app_tokens: string;
  having_stores: string;
}

//Your API response shape will probably be different. Knowing a total row count is important though.
type ApiResponse = Array<User>;

const MRTUsersGrid = () => {
  const [rowCount, setRowCount] = useState(0);
  //manage our own state for stuff we want to pass to the API
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    [],
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [currentEditData, setCurrentEditData] = useState({});
  const [currentEditId, setCurrentEditId] = useState("");

  const handleEditClick = (data: any) => {
    const { _id, createdAt, updatedAt, ...editData } = data;
    setCurrentEditData(editData);
    setCurrentEditId(_id);
    setEditDialogOpen(true);
  };

  const handleSaveEditedData = (data: any) => {
    setEditDialogOpen(false);
    updateUser({ ...data, _id: currentEditId });
  };

  const { mutateAsync: updateUser } = useUpdateUser();

  function useUpdateUser() {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: async (dataContainingId: any) => {
        const { _id, ...data } = dataContainingId;
        await fetch("/users/" + currentEditId, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
      },
      //client side optimistic update
      onMutate: (newData: any) => {
        queryClient.setQueryData(
          [
            "table-data",
            columnFilters, //refetch when columnFilters changes
            globalFilter, //refetch when globalFilter changes
            pagination.pageIndex, //refetch when pagination.pageIndex changes
            pagination.pageSize, //refetch when pagination.pageSize changes
            sorting, //refetch when sorting changes
          ],
          (prevData: any) => {
            return prevData?.map((prevData: any) =>
              prevData._id === newData._id ? newData : prevData,
            );
          },
        );
      },
      // onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }), //refetch users after mutation, disabled for demo
    });
  }

  //if using dynamic columns that are loaded after table instance creation, we will need to manage the column order state ourselves
  //UPDATE: No longer needed as of v2.10.0
  const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>([]);

  //consider storing this code in a custom hook (i.e useFetchUsers)
  const {
    data = [], //your data and api response will probably be different
    isError,
    isRefetching,
    isLoading,
    refetch,
  } = useQuery<Array<GridUser>>({
    queryKey: [
      "table-data",
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
    ],
    queryFn: async () => {
      const fetchURL = new URL(
        "/users",
        // "http://localhost:3000"
        process.env.NODE_ENV === "production"
          ? "https://ranking.micomprocedure.com"
          : "http://localhost:3000",
      );
      //read our state and pass it to the API as query params
      fetchURL.searchParams.set(
        "start",
        `${pagination.pageIndex * pagination.pageSize}`,
      );
      fetchURL.searchParams.set("limit", `${pagination.pageSize}`);
      // fetchURL.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      //   fetchURL.searchParams.set('globalFilter', globalFilter ?? '');
      if (sorting.length > 0) {
        fetchURL.searchParams.set("sorting", JSON.stringify(sorting[0].id));
        if (sorting[0].desc) {
          fetchURL.searchParams.set("direction", "1");
        }
      }

      //use whatever fetch library you want, fetch, axios, etc
      const response = await fetch(fetchURL.href);
      // try {
      //   setRowCount(
      //     parseInt(response.headers.get("X-RankingDataCount") ?? "0"),
      //   );
      // } catch {
      //   console.error("Cannot get rowcount");
      //   setRowCount(0);
      // }
      const json = (await response.json()) as ApiResponse;
      setRowCount(json.length);
      return json.map((e: User) => {
        const { having_stores, app_tokens, ...others } = e;
        const storeNames = having_stores.map((v: any) => v.name).join(", ");
        const tokenNames = app_tokens.map((v: any) => v.name).join(", ");
        return { ...others, having_stores: storeNames, app_tokens: tokenNames };
      }) as Array<GridUser>;
    },
    placeholderData: keepPreviousData, //don't go to 0 rows when refetching or paginating to next page
  });

  const columnsHeaderMap: { [key: string]: string } = {
    _id: "ObjectID",
    createdAt: "Created At",
    updatedAt: "Updated At",
  };

  const columns = useMemo<MRT_ColumnDef<GridUser>[]>(
    () =>
      data.length
        ? Object.keys(data[0]).map((columnId) => ({
            header: columnsHeaderMap[columnId as keyof GridUser] ?? columnId,
            accessorKey: columnId,
            id: columnId,
          }))
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  const sortFn = (a: string, b: string) => {
    if (b === "_id") return 1;
    if (a === "_id") return -1;
    if (b === "updatedAt") return -1;
    if (a === "updatedAt") return 1;
    if (b === "createdAt") return -1;
    if (a === "createdAt") return 1;
    return 0;
  };

  //UPDATE: No longer needed as of v2.10.0
  useEffect(() => {
    //if using dynamic columns that are loaded after table instance creation, we will need to set the column order state ourselves
    setColumnOrder([
      ...(columns ?? [])
        .map((column) => column.id!)
        // .sort()
        .sort(sortFn),
    ]);
  }, [columns]);

  const table = useMaterialReactTable({
    columns: columns ?? [],
    data,
    initialState: { showColumnFilters: true },
    manualFiltering: true, //turn off built-in client-side filtering
    manualPagination: true, //turn off built-in client-side pagination
    manualSorting: true, //turn off built-in client-side sorting
    enableMultiSort: false,
    enableGlobalFilter: false,
    //give loading spinner somewhere to go while loading
    muiTableBodyProps: {
      children: isLoading ? (
        <tr style={{ height: "200px" }}>
          <td />
        </tr>
      ) : undefined,
    },
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    editDisplayMode: "modal",
    enableEditing: true,
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => handleEditClick(row.original)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    onColumnFiltersChange: setColumnFilters,
    onColumnOrderChange: setColumnOrder,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    renderTopToolbarCustomActions: () => (
      <Tooltip arrow title="Refresh Data">
        <IconButton onClick={() => refetch()}>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    ),
    rowCount,
    state: {
      columnFilters,
      columnOrder,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting: [
        {
          id: "updatedAt",
          desc: true,
        },
      ],
    },
  });

  return (
    <div>
      <MaterialReactTable table={table} />
      <PatchDialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        data={currentEditData}
        onSave={handleSaveEditedData}
      ></PatchDialog>
    </div>
  );
};

const queryClient = new QueryClient();

const MRTUsersGridWithQueryProvider = () => (
  //App.tsx or AppProviders file. Don't just wrap this component with QueryClientProvider! Wrap your whole App!
  <QueryClientProvider client={queryClient}>
    <MRTUsersGrid />
  </QueryClientProvider>
);

export default MRTUsersGridWithQueryProvider;
