import {
  Alert,
  AlertColor,
  IconButton,
  Snackbar,
  SnackbarCloseReason,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import customSnackbarSlice from "../redux/customSnackbarSlice";

interface CustomSnackbarProps {
  onClose?:
    | ((
        event: Event | React.SyntheticEvent<any, Event>,
        reason?: SnackbarCloseReason,
      ) => void)
    | undefined;
  show?: boolean;
  duration?: number;
  message?: string;
  type?: AlertColor;
}

const CustomSnackbar = (props: CustomSnackbarProps) => {
  const show = useSelector((state: RootState) => state.snackbar.show);
  const duration = useSelector((state: RootState) => state.snackbar.duration);
  const message = useSelector((state: RootState) => state.snackbar.message);
  const type = useSelector((state: RootState) => state.snackbar.type);
  const dispatch = useDispatch();
  const setShow = (state: boolean) =>
    dispatch(customSnackbarSlice.actions.setShow(state));

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setShow(false);
  };

  const SnackbarAction = (props: {
    handleClose:
      | ((
          event: Event | React.SyntheticEvent<any, Event>,
          reason?: SnackbarCloseReason,
        ) => void)
      | undefined;
  }) => {
    return (
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={props.handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    );
  };

  return (
    <Snackbar
      open={props.show !== undefined ? props.show : show}
      autoHideDuration={
        props.duration !== undefined ? props.duration : duration
      }
      onClose={props.onClose !== undefined ? props.onClose : handleClose}
      action={
        <SnackbarAction
          handleClose={() =>
            dispatch(customSnackbarSlice.actions.setShow(false))
          }
        />
      }
    >
      <Alert
        onClose={props.onClose}
        severity={props.type !== undefined ? props.type : type}
      >
        {props.message !== undefined ? props.message : message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
