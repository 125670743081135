import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Alert, CircularProgress, Stack } from "@mui/material";
import OperatingAPI from "./OperatingAPI";
import MRTDatagridWithQueryProvider from "./Datagrid";
import ReactGA from "react-ga4";

const ShowStore = () => {
  const urlParams = useParams<{ id: string }>();
  // const [isAvailable, setAvailable] = useState(false);

  const [available, setAvailable] = useState(false);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setAvailable(false);
    setLoading(true);
    fetch("/dstore/" + urlParams.id + "/columns").then((res) => {
      if (res.ok) {
        setAvailable(true);
      }
      setLoading(false);
    });
    ReactGA.send({ hitType: "pageview", page: "/store" });
    document.title = "ProcRa | " + urlParams.id;
  }, [urlParams.id]);

  if (isLoading) return <CircularProgress />;
  else if (!available) {
    return (
      <Alert severity="error">
        データストアをロードできませんでした。
        <br />
        リロードしても直らない場合は、"{urlParams.id}
        "のデータストアが存在することを確認してください。
      </Alert>
    );
  } else {
    return (
      <Stack spacing={3}>
        <MRTDatagridWithQueryProvider id={urlParams.id ?? ""} />
        <OperatingAPI id={urlParams.id} />
      </Stack>
    );
  }
};

export default ShowStore;
