import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { useEffect, useState } from "react";

export interface NewsDoc {
  title: string;
  content: string;
  author: string;
  updatedAt: string;
  _id: string;
}

const News = () => {
  const [newsElements, setNewsElements] = useState([] as JSX.Element[]);

  useEffect(() => {
    fetch("/news")
      .then((res) => res.json())
      .then((v) => setNewsElements(v?.map((e: NewsDoc) => genCard(e))));
  }, []);

  const genCard = (n: NewsDoc) => {
    return (
      <Paper
        sx={{ padding: 2, margin: "auto", maxWidth: 500, textAlign: "left" }}
        key={n._id}
      >
        <Typography variant="h5" component="h3">
          {n.title}
        </Typography>
        <Typography component="p" textAlign="right">
          Author: {n.author}
        </Typography>
        <Typography component="p" textAlign="right">
          Last Updated: {new Date(n.updatedAt).toLocaleString()}
        </Typography>
        <Divider sx={{ marginY: 2 }} />
        <Typography component="p" whiteSpace="pre-line">
          {decodeURIComponent(n.content)}
        </Typography>
      </Paper>
    );
  };

  return <div>{newsElements}</div>;
};

export default News;
