import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { Alert, Box, Divider, Typography } from "@mui/material";
import News from "./News";
import { useEffect } from "react";
import ReactGA from "react-ga4";

const Home = () => {
  const username = useSelector((state: RootState) => state.user.username);

  useEffect(() => {
    ReactGA.send("pageview");
    document.title = "Proc-Ranking";
  });

  return (
    <div>
      {username ? (
        <Box margin="10px" alignContent="flex-start" gap="20px">
          <Box margin="20px">
            <Typography>ようこそ、{username}さん！</Typography>
          </Box>
          <Box gap="10px">
            <Typography textAlign="left" variant="h5">
              ニュース
            </Typography>
            <Divider />
            <News />
          </Box>
        </Box>
      ) : (
        <Box margin="10px">
          <Alert severity="error">ログインしてください</Alert>
        </Box>
      )}
    </div>
  );
};

export default Home;
