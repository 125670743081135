import { Alert, Box } from "@mui/material";
import UsersGrid from "./UsersGrid";
import { useEffect } from "react";
import ReactGA from "react-ga4";

const ShowUsers = () => {
  useEffect(() => {
    ReactGA.send("pageview");
    document.title = "ProcRa | [Admin]Users";
  });

  return (
    <Box gap={5}>
      <Alert severity="warning">
        この項目は主に閲覧向けです！必要なときにだけ変更してください！
      </Alert>
      <UsersGrid />
    </Box>
  );
};

export default ShowUsers;
