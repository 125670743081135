import { AlertColor } from "@mui/material";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface SnackbarConfig {
  message: string;
  type: AlertColor;
}

const customSnackbarSlice = createSlice({
  name: "customSnackbar",
  initialState: {
    show: false,
    message: "",
    type: "info" as AlertColor,
    duration: 6000,
  },
  reducers: {
    setShow: (state, action: PayloadAction<boolean>) => {
      state.show = action.payload;
    },
    setMessage: (state, value: PayloadAction<string>) => {
      state.message = value.payload;
    },
    setType: (state, value: PayloadAction<AlertColor>) => {
      state.type = value.payload;
    },
    setDuration: (state, value: PayloadAction<number>) => {
      state.duration = value.payload;
    },
    configure: (state, value: PayloadAction<SnackbarConfig>) => {
      state.show = true;
      state.message = value.payload.message;
      state.type = value.payload.type;
    },
  },
});

export const { setShow, setMessage, setType, setDuration } =
  customSnackbarSlice.actions;

export default customSnackbarSlice;
