import { Alert } from "@mui/material";
import React, { useEffect } from "react";
import ReactGA from "react-ga4";

const LoggedOut = () => {
  useEffect(() => {
    ReactGA.send("pageview");
    ReactGA.event("logout");
    document.title = "ProcRa | Logout";
  });

  return <Alert severity="info">ログアウトしました</Alert>;
};

export default LoggedOut;
