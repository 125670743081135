import { Button, IconButton, Toolbar, Typography, styled } from "@mui/material";
import { memo, useEffect } from "react";

import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../redux/store";
import AccountMenu from "./AccountMenu";
import { Link } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import userSlice from "../redux/userSlice";
import appStateSlice from "../redux/appStateSlice";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Appbar = () => {
  const userId = useSelector((state: RootState) => state.user.userId);
  const username = useSelector((state: RootState) => state.user.username);
  const icon = useSelector((state: RootState) => state.user.icon);
  // const open = useSelector((state: RootState) => state.drawer.open);
  const drawerOpen = useSelector((state: RootState) => state.app.drawerOpen);
  const dispatch = useDispatch();

  useEffect(() => {
    fetch("/users/@me")
      .then(async (res) => {
        let user = await res.json();
        if (!user) return;
        dispatch(userSlice.actions.setUsername(user.display_name));
        dispatch(userSlice.actions.setUserId(user.discord_id));
        dispatch(userSlice.actions.setIcon(user.icon));
        dispatch(userSlice.actions.setRole(user.role));
        dispatch(userSlice.actions.setTokens(user.app_tokens));
        dispatch(
          userSlice.actions.setStores(
            user.having_stores?.map(
              (e: { name: string; id: string }) => e.name,
            ),
          ),
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }, [dispatch]);

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={drawerOpen}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => dispatch(appStateSlice.actions.setDrawerOpen(true))}
          edge="start"
          sx={{
            marginRight: 5,
            ...(drawerOpen && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Link to="/" style={{ textDecoration: "none", color: "white" }}>
            ProcRanking Dashboard
          </Link>
        </Typography>
        {username ? (
          <AccountMenu username={username} userId={userId} icon={icon} />
        ) : (
          <Button
            color="inherit"
            href={
              (process.env.NODE_ENV === "development"
                ? "http://localhost:3002"
                : "") + "/auth/login"
            }
          >
            ログイン
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

const MemoAppBar = memo(Appbar);

export default MemoAppBar;
