import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import customSnackbarSlice from "../redux/customSnackbarSlice";
import { AlertColor, IconButton } from "@mui/material";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const parseAndUploadmBaaS = (
  content: string,
  id: string,
  // currentRows: any[],
  // setRows: Function,
  // setReload: Function,
  snackbarConfigure: (type: AlertColor, msg: string) => void,
  onSuccess: (data: any[]) => any,
) => {
  try {
    const obj = JSON.parse(content);
    let data: any[] = [];
    if (Object.hasOwn(obj, "results")) {
      const records = obj.results as any[];
      if (records.constructor === Array) {
        for (let i = 0; i < records.length; i++) {
          let r: any = records[i];
          let createdAt = r?.createDate?.iso;
          let updatedAt = r?.updateDate?.iso;
          delete r["acl"];
          delete r["createDate"];
          delete r["updateDate"];
          delete r["objectId"];
          data.push({ ...r, createdAt: createdAt, updatedAt: updatedAt });
        }
      }
      fetch("/dstore/" + id + "/bulkcreate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      }).then((res) => {
        if (res.ok) {
          onSuccess(data);
        } else {
          snackbarConfigure("error", "挿入に失敗しました");
        }
      });
    }
  } catch (e) {
    throw e;
  }
};

const parseAndUploadGeneral = (
  content: string,
  id: string,
  // currentRows: any[],
  // setRows: Function,
  // setReload: Function,
  snackbarConfigure: (type: AlertColor, msg: string) => void,
  onSuccess: (data: any[]) => any,
) => {
  try {
    const obj = JSON.parse(content);
    fetch("/dstore/" + id + "/bulkcreate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: obj }),
    }).then((res) => {
      if (res.ok) {
        onSuccess(obj);
      } else {
        snackbarConfigure("error", "挿入に失敗しました");
      }
    });
  } catch (e) {
    throw e;
  }
};

const ImportButton = (props: { onSuccess: (data: any[]) => any }) => {
  const urlParams = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null as null | HTMLElement);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const snackbarConfigure = (type: AlertColor, msg: string) =>
    dispatch(
      customSnackbarSlice.actions.configure({ type: type, message: msg }),
    );

  const handleUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: "mBaaS" | "general",
    onSuccess: (data: any[]) => any,
  ) => {
    const files = event.currentTarget.files;
    if (!files || files?.length === 0) return;
    const file = files[0];
    if (!file.name.endsWith(".json")) {
      snackbarConfigure("error", "JSONファイルのみアップロードできます");
      return;
    }

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      if (typeof reader.result === "string") {
        if (type === "mBaaS") {
          parseAndUploadmBaaS(
            reader.result,
            urlParams.id ?? "",
            snackbarConfigure,
            onSuccess,
          );
        } else {
          parseAndUploadGeneral(
            reader.result,
            urlParams.id ?? "",
            snackbarConfigure,
            onSuccess,
          );
        }
      }
    });
    reader.readAsText(file, "UTF-8");
  };

  return (
    <div>
      <IconButton
        id="import-button"
        aria-controls={open ? "customized-import-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        // disableElevation
        onClick={handleClick}
        // endIcon={<KeyboardArrowDownIcon />}
      >
        <FileUploadIcon />
      </IconButton>
      <StyledMenu
        id="customized-import-menu"
        MenuListProps={{
          "aria-labelledby": "import-button",
        }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
      >
        <label htmlFor="upload-from-mbaas">
          <MenuItem disableRipple>
            <input
              type="file"
              id="upload-from-mbaas"
              accept="application/json"
              hidden
              onChange={(e) => handleUpload(e, "mBaaS", props.onSuccess)}
            />
            ニフクラmBaaS形式でインポート
          </MenuItem>
        </label>
        <label htmlFor="upload-json">
          <MenuItem disableRipple>
            <input
              type="file"
              id="upload-json"
              accept="application/json"
              hidden
              onChange={(e) => handleUpload(e, "general", props.onSuccess)}
            />
            他のJSON形式でインポート
          </MenuItem>
        </label>
      </StyledMenu>
    </div>
  );
};

export default ImportButton;
